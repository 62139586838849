var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-15" },
    [
      _c("v-layout", [
        _c("h1", { staticClass: "u-green-text mb-0" }, [
          _vm._v(_vm._s(_vm.$t("MyMessage"))),
        ]),
      ]),
      _vm.pages.length > 0
        ? _c(
            "v-layout",
            { staticStyle: { width: "100px" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.itemsPerPage,
                  label: _vm.$t("ItemsPerPage"),
                },
                model: {
                  value: _vm.perPage,
                  callback: function ($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-list-item-group",
        { attrs: { color: "indigo" } },
        [
          _c(
            "v-list",
            { attrs: { "three-line": "" } },
            [
              _vm._l(_vm.visiblePages(), function (item, index) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: item.id,
                      attrs: {
                        to: {
                          name: "DetailMessage",
                          params: {
                            tuplet_id: item.id,
                            name: item.title,
                          },
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c("v-img", {
                            attrs: { src: require("@/assets/icons/car.png") },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { innerHTML: _vm._s(item.title) },
                          }),
                          _c("v-list-item-subtitle", {
                            domProps: { innerHTML: _vm._s(item.subtitle) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { key: index, attrs: { inset: true } }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.pages.length > 0
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-pagination", {
                attrs: {
                  length: _vm.pagesCount(),
                  "prev-icon": "mdi-menu-left",
                  "next-icon": "mdi-menu-right",
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.pages.length == 0
        ? _c(
            "v-layout",
            { staticStyle: { "justify-content": "center" } },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    icon: "mdi-information-outline",
                    text: "",
                    type: "info",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("NoMessages")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }