import { render, staticRenderFns } from "./MyMessages.vue?vue&type=template&id=15255dda&"
import script from "./MyMessages.vue?vue&type=script&lang=ts&"
export * from "./MyMessages.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAlert,VContainer,VDivider,VImg,VLayout,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VPagination,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15255dda')) {
      api.createRecord('15255dda', component.options)
    } else {
      api.reload('15255dda', component.options)
    }
    module.hot.accept("./MyMessages.vue?vue&type=template&id=15255dda&", function () {
      api.rerender('15255dda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/message/MyMessages.vue"
export default component.exports